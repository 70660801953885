'use strict'

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import {API_URL, TOKEN_NAME, MATCH_ROUTE_NAMES} from './ConstantService'
import {AlertService} from './AlertService'
import {AuthenticationService} from "@/services/AuthenticationService";
import router from "@/router";

export const  GlobalService = {
  initializeAxios() {
    Vue.use(VueAxios, axios)
    axios.defaults.baseURL = API_URL
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(TOKEN_NAME)
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(undefined, (error) => {
      const config = error.config
      if (error.response.status === 503) {
        AuthenticationService.clearStorage();
        AuthenticationService.deleteHeader();
        router.push({name: 'MaintenanceMode'})
      }
      if (error.response.status === 401 && !config._retry) {
        config._retry = true
        AuthenticationService.clearStorage();
        AuthenticationService.deleteHeader();
        if (router.currentRoute.name !== 'Login') {
          router.push({name: 'Login'})
        }
      }
      return Promise.reject(error);
    })
  },
  async ajax(method, uri, params = {}, options = {}) {

    let _source = params.hasOwnProperty('_source') ? String(params._source) : uri.toUpperCase().split('/').join(' ');
    _source = options.hasOwnProperty('source') ? options.source : _source;

    return await axios[method](uri, params).then(response => {
      return response;
    }).catch(error => {
      const _error = GlobalService.errorCatcher(error);

      AlertService.errorAlert(_error.message, _source);

      return _error;
    });
  },
  errorCatcher(error) {
    let _error = {
      response: {
        headers: null,
        status: null,
        data: {},
      },
      request: {},
      config: {},
      errors: {},
      message: null,
    };

    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    _error.request = error.hasOwnProperty('request') ? error.request : _error.request;
    _error.config = error.hasOwnProperty('config') ? error.config : _error.config;
    _error.response = error.hasOwnProperty('response') ? error.response : _error.response;
    _error.errors = error.hasOwnProperty('errors') ? error.errors : _error.errors;
    _error.message = error.hasOwnProperty('message') ? error.message : _error.message;

    _error.errors = _error.response.data.hasOwnProperty('errors') ? _error.response.data.errors : _error.errors;
    _error.message = (_error.response.data.hasOwnProperty('message') && (_error.response.data.message).length) ? _error.response.data.message : _error.message;

    let _error_message = _error.message;
    if (Object.keys(_error.errors).length) {
      _error_message += '<br><br><ol style="text-align: left; font-size: 0.9em">';

      Object.keys(_error.errors).forEach(key => {
        let err_msg_arr = Array.isArray(_error.errors[key]) ? _error.errors[key] : [_error.errors[key]];

        err_msg_arr.forEach(err_msg => {
          ["ID-(.*)-ID", "PSCID-(.*)-PSCID"].forEach(replacePattern => {
            const err_msg_match = String(err_msg).match(new RegExp(replacePattern));

            if (err_msg_match) {
              const matched_str = err_msg_match[0];
              const target_str = err_msg_match[1].split("|");
              const target_id = target_str[0];
              let target_route_name = (typeof target_str[1] !== 'undefined') ? target_str[1] : null;
              let route_name_key = err_msg_match[0].split('-').shift().toLowerCase();

              if (!target_route_name && MATCH_ROUTE_NAMES.hasOwnProperty(route_name_key)) {
                target_route_name = MATCH_ROUTE_NAMES[route_name_key];
              }

              if (target_route_name && target_id) {
                const target_route = {name: target_route_name, params: {id: target_id}};
                const target_route_resolve = router.resolve(target_route);
                const target_route_link = (target_route_resolve).href;

                if (target_route_resolve.resolved.matched.length) {
                  err_msg = err_msg.replace(matched_str, target_route_link);
                }
              }
            }
          });

          _error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
        });
      });

      _error_message += '</ol>';
    }

    _error.message = _error_message;

    // Flatten _error.response
    _error = {..._error.response, ..._error};

    return _error;
  },

  handleError(error, source) {
    try {
      switch (error.response.status) {
        case 401:
          AlertService.errorAlert('UNAUTHORIZED', source)

        // eslint-disable-next-line no-fallthrough
        case 403:
          AlertService.errorAlert('UNAUTHORIZED', source)
          return false

        case 404:
          AlertService.errorAlert('File Not Found', source)

        // eslint-disable-next-line no-fallthrough
        case 422:
          AlertService.errorAlert('UNPROCESSABLE CONTENT', source)
          return false

        case 500:
          AlertService.errorAlert('SYSTEM ERROR', source)
          return false

        case 400:
          this.handleRequestErrors(error.response.data.errors, source)
          return this.parseErrors(error.response.data.errors)
      }
      return {errors: error.response.data.errors}
    } catch (e) {
      // console.log(e, source)
    }
  },

  capitalizeProperties(value, except) {
    try {
      Object.keys(value).forEach(key => {
        if (except) {
          if (except.includes(key)) return
        }

        if (value[key] && typeof value[key] === 'string') {
          if (!this.caseException.includes(key)) {
            value[key] = (value[key]) ? String(value[key]).toUpperCase() : '';
          }
        } else {
          value[key] = this.capitalizeProperties(value[key]);
        }
      });
    } catch (e) {
      return value;
    }
    localStorage.setItem('idleSeconds', 0);
    return value;
  },

  sortFunc(a, b, key = 'name') {
    let firstObj = a[key].toLowerCase();
    let secondObj = a[key].toLowerCase();

    if (firstObj < secondObj) return -1

    if (firstObj > secondObj) return 1

    return 0
  }

}

export default {GlobalService}
