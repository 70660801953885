import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// for styling
/********/
  // import 'bootstrap'
/*******/
import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '../public/css/custom.css'
import '../public/css/compiled-sass.css'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);
// font awesome
window.$=require('jquery');
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faThLarge,
  faFolderOpen,
  faClipboard,
  faShip,
  faChartLine,
  faBars,
  faUser,
  faSignInAlt,
  faSignOutAlt,
  faPlusSquare,
  faEye,
  faEyeSlash,
  faNotesMedical,
  faFileContract,
  faInfoCircle,
  faFileSignature,
  faChevronLeft,
  faPen,
  faBan,
  faSave,
  faUpload,
  faFilePdf,
  faFile,
  faTrash,
  faCogs,
  faDownload,
  faUsers,
  faPlus,
  faAnchor,
  faList,
  faMoneyBill,
  faCalendar,
  faCarCrash,
  faCheckDouble,
  faFileExcel,
  faSearch,
  faQuestionCircle,
  faCheck,
  faEnvelope,
  faTimes,
  faCog,
  faChevronDown,
  faChevronRight,
  faSpinner,
  faShare,
  faFilter,
  faArrowLeft,
  faArrowRight,
  faBriefcase,
  faArrowUp,
  faArrowDown,
  faCalculator,
  faCircle,
  faSquare,
  faStar,
  faChevronUp,
  faBuilding,
  faCaretSquareDown,
  faLongArrowAltDown,
  faSortDown,
  faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

// apex charts
import VueApexCharts from 'vue-apexcharts'
import Vue2Editor from "vue2-editor";
Vue.use(VueApexCharts)
Vue.use(Vue2Editor)
Vue.component('apexchart', VueApexCharts)
Vue.component('vueeditor',Vue2Editor)

// Axios in Global Service
import {GlobalService} from './services/GlobalService'
import {AlertService} from './services/AlertService'

library.add(faThLarge,
  faFolderOpen,
  faClipboard,
  faShip,
  faChevronRight,
  faChartLine,
  faBars,
  faUser,
  faSignInAlt,
  faSignOutAlt,
  faPlusSquare,
  faEye,
  faEyeSlash,
  faNotesMedical,
  faFileContract,
  faInfoCircle,
  faFileSignature,
  faChevronUp,
  faChevronLeft,
  faPen,
  faBan,
  faSave,
  faCheckDouble,
  faFilePdf,
  faTrash,
  faCogs,
  faFile,
  faUpload,
  faDownload,
  faUsers,
  faPlus,
  faAnchor,
  faList,
  faMoneyBill,
  faCalendar,
  faCarCrash,
  faFileExcel,
  faSearch,
  faQuestionCircle,
  faCheck,
  faTimes,
  faCog,
  faChevronDown,
  faEnvelope,
  faSpinner,
  faThLarge,
  faShare,
  faFilter,
  faArrowLeft,
  faBriefcase,
  faArrowUp,
  faArrowDown,
  faCalculator,
  faCircle,
  faSquare,
  faStar,
  faBuilding,
  faCaretSquareDown,
  faLongArrowAltDown,
  faSortDown,
  faArrowRight,
  faFolderPlus
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(require('vue-moment'))
Vue.config.productionTip = false
GlobalService.initializeAxios()
AlertService.initializeSwal();

(function (parameters) {
  const VueFilters = {
    toInt: function(item) {
      return item ? parseInt(item) : 0;
    },
    toUpper: function (item){
      return typeof item === 'string' || item instanceof String ?  item.toUpperCase() : item;
    },
    toYesOrNo: function (item){
      return item ? 'Yes' : 'No';
    },
    strTitle: function(str) {
      return (str + '').replace(/\w\S*/g, txt => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()));
    },
  };

  Object.keys(VueFilters).forEach(filter_name => {
    Vue.filter(filter_name, VueFilters[filter_name]);
  });
})();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
